// Weather icon mapping based on WMO codes
// Reference: https://github.com/open-meteo/open-meteo/blob/5c500245521abedc4315d7acf828a0c17fb97029/Sources/App/Helper/WeatherCode.swift#L4
export function getWeatherIcon(code) {
  if (!code) return './data/weatherIcons/unknown.svg';
  
  // Map WMO codes to local SVG icons
  const iconMap = {
    '0': './data/weatherIcons/day.svg',  // Clear sky
    '1': './data/weatherIcons/cloudy-day-1.svg',  // Mainly Clear
    '2': './data/weatherIcons/cloudy-day-2.svg',  // Partly cloudy
    '3': './data/weatherIcons/cloudy.svg',  // Overcast
    '45': './data/weatherIcons/cloudy.svg', // Fog
    '48': './data/weatherIcons/cloudy.svg', // depositingRimeFog
    '51': './data/weatherIcons/rainy-2.svg', // Light drizzle
    '53': './data/weatherIcons/rainy-1.svg', // Moderate drizzle
    '55': './data/weatherIcons/rainy-3.svg', // Dense drizzle
    '56': './data/weatherIcons/rainy-2.svg', // Light freezing drizzle
    '57': './data/weatherIcons/rainy-1.svg', // Moderate or Dense freezing drizzle
    '61': './data/weatherIcons/rainy-4.svg', // light rain
    '63': './data/weatherIcons/rainy-5.svg', // moderate rain
    '65': './data/weatherIcons/rainy-6.svg', // heavy rain
    '66': './data/weatherIcons/rainy-4.svg', // light freezing rain
    '67': './data/weatherIcons/rain-7.svg', // moderate or heavy freezing rain
    '71': './data/weatherIcons/snowy-4.svg', // light snow
    '73': './data/weatherIcons/snowy-5.svg', // moderate snow
    '75': './data/weatherIcons/snowy-6.svg', // heavy snow
    '77': './data/weatherIcons/snowy-6.svg', // snow grains
    '80': './data/weatherIcons/rainy-2.svg', // slight rain showers
    '81': './data/weatherIcons/rainy-1.svg', // moderate rain showers
    '82': './data/weatherIcons/rainy-3.svg', // heavy rain showers
    '85': './data/weatherIcons/snowy-2.svg', // slight snow showers
    '86': './data/weatherIcons/snowy-3.svg', // heavy snow showers
    '95': './data/weatherIcons/thunder.svg', // thunderstorm slight or moderate
    '96': './data/weatherIcons/thunder.svg', // thunderstorm strong
    '99': './data/weatherIcons/thunder.svg', // thunderstorm heavy
  };

  return iconMap[code] || './data/weatherIcons/unknown.svg';
} 