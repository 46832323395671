import './style.css';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { fromLonLat, transform } from 'ol/proj';
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Cluster from 'ol/source/Cluster';
import GeoJSON from 'ol/format/GeoJSON';
import { Style, Circle, Fill, Stroke, Text, Icon } from 'ol/style';
import Overlay from 'ol/Overlay';
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';
import XYZ from 'ol/source/XYZ';
import { getWeatherIcon } from './weatherIcons';

// Define NZTM projection (EPSG:2193)
proj4.defs("EPSG:2193", "+proj=tmerc +lat_0=0 +lon_0=173 +k=0.9996 +x_0=1600000 +y_0=10000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
register(proj4);

// Create the map with only the topo basemap
const map = new Map({
  target: 'map',
  layers: [
    new TileLayer({
      source: new XYZ({
        url: 'https://{a-c}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png',
        attributions: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, &copy; <a href="https://carto.com/attributions">CARTO</a>',
        crossOrigin: 'anonymous'
      })
    })
  ],
  view: new View({
    projection: "EPSG:2193",
    center: [847762, 5427052],
    zoom: 5,
    minZoom: 5,
    maxZoom: 11,
    extent: [90000, 4000000, 2400000, 6500000],
    rotation: 0,
    enableRotation: false
  })
});

// Add satellite layer - Oct 28
let satelliteLayer = null;

// Function to fetch and display the latest satellite data
async function loadLatestSatellite() {
  try {
    const response = await fetch('https://api.rainviewer.com/public/weather-maps.json');
    const data = await response.json();

    // Remove existing satellite layer if it exists
    if (satelliteLayer) {
      map.removeLayer(satelliteLayer);
    }

    // Get the most recent timestamp
    const latestTimestamp = data.satellite.infrared[data.satellite.infrared.length - 1].path;

    // Create new satellite layer
    satelliteLayer = new TileLayer({
      source: new XYZ({
        url: `https://tilecache.rainviewer.com/${latestTimestamp}/256/{z}/{x}/{y}/0/0_0.png`,
        crossOrigin: 'anonymous'
      }),
      opacity: 0.5
    });

    // Add the satellite layer to the map
    map.addLayer(satelliteLayer);

    // Set the satellite layer to be hidden by default
    satelliteLayer.setVisible(false);

  } catch (error) {
    console.error('Error loading satellite data:', error);
  }
}

// Load initial satellite data
loadLatestSatellite();
// Refresh satellite data every 10 minutes
setInterval(loadLatestSatellite, 10 * 60 * 1000);
// End of adding satellite layer

// Add radar layer - Oct 27
let radarLayer = null;

// Function to fetch and display the latest radar data
async function loadLatestRadar() {
  try {
    const response = await fetch('https://api.rainviewer.com/public/weather-maps.json');
    const data = await response.json();

    // Remove existing radar layer if it exists
    if (radarLayer) {
      map.removeLayer(radarLayer);
    }

    // Get the most recent timestamp
    const latestTimestamp = data.radar.past[data.radar.past.length - 1].time;

    // Create new radar layer
    radarLayer = new TileLayer({
      source: new XYZ({
        url: `https://tilecache.rainviewer.com/v2/radar/${latestTimestamp}/256/{z}/{x}/{y}/8/1_1.png`,
        crossOrigin: 'anonymous'
      }),
      opacity: 0.9
    });

    // Add the radar layer to the map
    map.addLayer(radarLayer);

  } catch (error) {
    console.error('Error loading radar data:', error);
  }
}

// Load initial radar data and refresh every 5 minutes
loadLatestRadar();
setInterval(loadLatestRadar, 5 * 60 * 1000);

// End of adding radar layer

document.dispatchEvent(new Event('mapRendered'));

// Update the loadWeatherCodes function with additional logging
async function loadWeatherCodes() {
  const BUCKET_URL = 'https://storage.googleapis.com/simpleweather-storage-bucket-dev/data-folder/data';
  const url = `${BUCKET_URL}/current_weathercode.json`;
  
  try {
    console.log('Fetching weather codes from:', url);
    const response = await fetch(url);
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const weatherCodes = await response.json();
    console.log('Weather codes loaded successfully:', Object.keys(weatherCodes).length, 'locations');
    return weatherCodes;
  } catch (error) {
    console.error('Error loading weather codes from bucket:', error);
    console.log('Attempting to load local fallback file...');
    
    // Attempt to load local fallback file
    try {
      const fallbackResponse = await fetch('./data/current_weathercode.json');
      const fallbackData = await fallbackResponse.json();
      console.log('Loaded fallback weather codes successfully');
      return fallbackData;
    } catch (fallbackError) {
      console.error('Failed to load fallback weather codes:', fallbackError);
      return {};
    }
  }
}

// Modify the vector source to include weather codes when loading features
const vectorSource = new VectorSource({
  url: './data/locations.geojson',
  format: new GeoJSON(),
  loader: async function(extent, resolution, projection, success, failure) {
    try {
      // Load both GeoJSON and weather codes
      const [geojsonResponse, weatherCodes] = await Promise.all([
        fetch('./data/locations.geojson'),
        loadWeatherCodes()
      ]);
      
      const geojsonData = await geojsonResponse.json();
      
      // Add weather codes to features
      geojsonData.features.forEach(feature => {
        const locationName = feature.properties.name.toLowerCase().replace(' ', '_');
        feature.properties.weatherCode = weatherCodes[locationName] || null;
      });
      
      // Parse features with the added weather codes
      const features = new GeoJSON().readFeatures(geojsonData, {
        featureProjection: projection
      });
      
      this.addFeatures(features);
      success(features);
    } catch (error) {
      failure();
    }
  }
});

// Create a cluster source
const clusterSource = new Cluster({
  distance: 40,
  source: vectorSource
});

// Modify the vector layer style function to use SVG icons
const vectorLayer = new VectorLayer({
  source: clusterSource,
  style: function(feature) {
    const size = feature.get('features').length;
    let color = 'rgba(76, 175, 80, 0.6)'; // Default color for clusters
    let singleFeatureColor = 'rgba(0, 0, 255, 0.01)'; // Very transparent background for single features

    if (size > 1) {
      // Cluster style remains the same
      return new Style({
        image: new Circle({
          radius: 30,
          fill: new Fill({ color: color }),
          stroke: new Stroke({ color: 'white', width: 2 })
        }),
        text: new Text({
          text: size.toString(),
          fill: new Fill({ color: 'white' }),
          stroke: new Stroke({ color: 'black', width: 5 }),
          font: 'bold 24px Arial, sans-serif'
        })
      });
    } else {
      // Single feature style with weather icon
      const singleFeature = feature.get('features')[0];
      const locationName = singleFeature.get('name').toLowerCase().replace(' ', '_');
      const type = singleFeature.get('type');
      
      // Adjust color based on type
      if (type === 'MS-only') {
        color = 'rgba(51, 0, 255, 0.7)';
        singleFeatureColor = 'rgba(51, 0, 255, 0.01)';
      } else if (type === 'MS-NIWA') {
        color = 'rgba(255, 204, 0, 0.7)';
        singleFeatureColor = 'rgba(255, 204, 0, 0.01)';
      } else if (type === "NIWA-only") {
        color = 'rgba(255, 204, 0, 0.7)';
        singleFeatureColor = 'rgba(255, 204, 0, 0.01)';
      } else {
        color = 'rgba(50, 50, 50, 0.7)';
        singleFeatureColor = 'rgba(50, 50, 50, 0.01)';
      }

      // Get weather code and icon path
      const weatherCode = singleFeature.get('weatherCode');
      const iconSrc = getWeatherIcon(weatherCode);

      // Create styles array with background circle and location name
      const styles = [
        new Style({
          image: new Circle({
            radius: 30,
            fill: new Fill({ color: singleFeatureColor }),
            stroke: new Stroke({ color: 'white', width: 0.5 })
          }),
          text: new Text({
            text: singleFeature.get('name'),
            offsetY: 25,
            fill: new Fill({ color: 'black' }),
            stroke: new Stroke({ color: 'white', width: 0.5 }),
            font: '12px Arial, sans-serif',
            textAlign: 'center',
            textBaseline: 'middle'
          })
        })
      ];

      // Add weather icon as an Icon style
      const weatherIconStyle = new Style({
        image: new Icon({
          src: iconSrc,
          scale: 1.2, // Adjust this value to change icon size
          anchor: [0.5, 0.5],
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction'
        })
      });
      styles.push(weatherIconStyle);

      return styles;
    }
  }
});

map.addLayer(vectorLayer);

// Create an overlay for the popup
const popup = new Overlay({
  element: document.createElement('div'),
  autoPan: true,
  autoPanAnimation: {
    duration: 250
  }
});
map.addOverlay(popup);

// Add this new function to create a highlight style
function createHighlightStyle(feature) {
  let size;
  try {
    size = feature.get('features').length;
  } catch (error) {
    return;
  }
  let color = 'rgba(255, 0, 0, 0.7)'; // Highlight color (red)
  let singleFeatureColor = 'rgba(0, 0, 0, 0.2)';

  if (size > 1) {
    return new Style({
      image: new Circle({
        radius: 30, // Slightly larger radius for emphasis
        fill: new Fill({ color: color }),
        stroke: new Stroke({ color: 'white', width: 3 })
      }),
      text: new Text({
        text: size.toString(),
        fill: new Fill({ color: 'white' }),
        stroke: new Stroke({ color: 'black', width: 5 })
      })
    });
  } else {
    return new Style({
      image: new Circle({
        radius: 37, // Slightly larger radius for emphasis
        fill: new Fill({ color: singleFeatureColor }),
        stroke: new Stroke({ color: 'black', width: 1 })
      }),
      text: new Text({
        text: feature.get('features')[0].get('name'),
        offsetY: 15,
        fill: new Fill({ color: 'black' }),
        stroke: new Stroke({ color: 'white', width: 5 })
      })
    });
  }
}

let highlightedFeature = null;

// Update the click event handler
map.on('click', function (evt) {
  const feature = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
    return feature;
  });

  // Remove highlight from previously highlighted feature
  if (highlightedFeature) {
    highlightedFeature.setStyle(null);
  }

  if (feature) {
    const features = feature.get('features');
    if (features.length === 1) {
      const singleFeature = features[0];
      const name = singleFeature.get('name').toLowerCase().replace(' ', '_');

      // Check if the clicked feature is already highlighted
      if (highlightedFeature === feature) {
        // If it is, remove the highlight and toggle the left column
        highlightedFeature.setStyle(null);
        highlightedFeature = null; // Clear the highlighted feature
        toggleLeftColumn(); // Call the toggle function
      } else {
        // Highlight the clicked feature
        feature.setStyle(createHighlightStyle(feature));
        highlightedFeature = feature;

        // Show the left column if it was hidden
        const leftColumn = document.querySelector('.left-column');
        if (leftColumn.classList.contains('collapsed')) {
          toggleLeftColumn();
        }
        // Reset to English before loading new weather data
        resetToEnglish();

        // Load weather data
        loadWeatherData(name);
      }
    } else {
      popup.getElement().style.display = 'none';
    }
  } else {
    popup.getElement().style.display = 'none';
  }
});

// Function to update location tags
function updateLocationTags(locationCounts) {
  const tagsContainer = document.getElementById('location-tags');
  tagsContainer.innerHTML = '';
  for (const [type, count] of Object.entries(locationCounts)) {
    const tag = document.createElement('span');
    tag.className = 'location-tag';
    tag.textContent = `${type}: ${count}`;
    tagsContainer.appendChild(tag);
  }
}

// Function to perform search
function performSearch() {
  const searchTerm = document.getElementById('location').value.toLowerCase();
  
  if (!searchTerm) return;
  
  const normalizedSearchTerm = searchTerm.replace(' ', '_');
  const foundFeatures = clusterSource.getSource().getFeatures().filter(feature =>
    feature.get('name').toLowerCase().includes(searchTerm)
  );
  
  if (foundFeatures.length > 0) {
    resetToEnglish();
    loadWeatherData(normalizedSearchTerm);
    
    if (highlightedFeature) {
      highlightedFeature.setStyle(null);
    }
    
    const firstFeature = foundFeatures[0];
    const featureCoordinates = firstFeature.getGeometry().getCoordinates();
    map.getView().animate({
      center: featureCoordinates,
      zoom: 11,
      duration: 1000
    });
    
    firstFeature.setStyle(createHighlightStyle(firstFeature));
    highlightedFeature = firstFeature;
    
    const leftColumn = document.querySelector('.left-column');
    if (leftColumn.classList.contains('collapsed')) {
      toggleLeftColumn();
    }
  } else {
    alert('Location not found. Please try another search.');
  }
}

// Function to reset map
// function resetMap() {
//   document.getElementById('location').value = '';
//   clusterSource.getSource().getFeatures().forEach(feature => {
//     feature.setStyle(null);
//   });
//   clusterSource.refresh();
// }

// Function to find nearest location
function findNearestLocation(userCoords) {
  let nearestFeature = null;
  let minDistance = Infinity;

  vectorSource.getFeatures().forEach(feature => {
    const featureCoords = feature.getGeometry().getCoordinates();
    const distance = Math.sqrt(
      Math.pow(userCoords[0] - featureCoords[0], 2) +
      Math.pow(userCoords[1] - featureCoords[1], 2)
    );
    if (distance < minDistance) {
      minDistance = distance;
      nearestFeature = feature;
    }
  });

  return nearestFeature;
}

// Function to handle "Find my location"
function findMyLocation() {
  if ('geolocation' in navigator) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const userCoords = transform(
          [position.coords.longitude, position.coords.latitude],
          'EPSG:4326',
          'EPSG:2193'
        );

        // Find nearest location
        const nearestFeature = findNearestLocation(userCoords);
        if (nearestFeature) {
          const name = nearestFeature.get('name').toLowerCase().replace(' ', '_');
          alert('Found closest site: ' + name.replace("_", " "));
          // Load weather data directly instead of redirecting
          loadWeatherData(name);
        } else {
          alert('No nearby locations found.');
        }
      },
      (error) => {
        console.error('Error getting location:', error);
        alert('Unable to get your location. Please try again or search manually.');
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      }
    );
  } else {
    alert('Geolocation is not supported by your browser. Please search manually.');
  }
}

// Add event listeners
// document.getElementById('search-button').addEventListener('click', performSearch);
document.getElementById('location').addEventListener('keypress', function (e) {
  if (e.key === 'Enter') {
    performSearch();
  }
});
// document.getElementById('find-location').addEventListener('click', findMyLocation);

// Update location tags when features are loaded
vectorSource.once('change', function () {
  if (vectorSource.getState() === 'ready') {
    const locationCounts = {};
    vectorSource.getFeatures().forEach(feature => {
      const type = feature.get('type');
      locationCounts[type] = (locationCounts[type] || 0) + 1;
    });
    updateLocationTags(locationCounts);
  }
});

// Function to save map state
function saveMapState() {
  const view = map.getView();
  const state = {
    center: view.getCenter(),
    zoom: view.getZoom()
  };
  localStorage.setItem('mapState', JSON.stringify(state));
}

// Function to restore map state
function restoreMapState() {
  const savedState = localStorage.getItem('mapState');
  if (savedState) {
    const { center, zoom } = JSON.parse(savedState);
    map.getView().setCenter(center);
    map.getView().setZoom(zoom);
  }
}

// Call restoreMapState on page load
document.addEventListener('DOMContentLoaded', restoreMapState);

// Add event listener to save map state before navigating away
window.addEventListener('beforeunload', saveMapState);

// Function to load weather data directly
function loadWeatherData(selectedLocation) {
  // Set the location title and Clear previous data and hide both tables
  document.getElementById('location-title').textContent = `Weather Results for ${capitalizeWords(selectedLocation)}`;
  document.getElementById('MetService-container').style.display = 'none';
  document.getElementById('NIWA-container').style.display = 'none';
  document.getElementById('location-name').textContent = capitalizeWords(selectedLocation);

  // Load MetService data (includes both weather data and statements)
  const metServicePromise = loadMetServiceData(`./data/ms_${selectedLocation}.csv`);
  // Load NIWA data (includes both weather data and statements)
  const niwaPromise = loadNIWAData(`./data/niwa_${selectedLocation}.csv`);
  const omPromise = getLatLonByLocationName(capitalizeWords(selectedLocation.replace("_", " ")))
    .then(result => {
      if (result) {
        return fetchWeatherData(result.coord[1], result.coord[0]);
      }
      return Promise.reject('Location not found');
    });

  // Wait for all promises to resolve or reject
  Promise.allSettled([metServicePromise, niwaPromise, omPromise]).then(results => {
    let metServiceHasData = false;
    let niwaHasData = false;
    let omHasData = false;

    results.forEach((result, index) => {
      if (result.status === 'fulfilled' && result.value === true) {
        if (index === 0) { // MetService data
          document.getElementById('MetService-container').style.display = 'block';
          metServiceHasData = true;
        } else if (index === 1) { // NIWA data
          document.getElementById('NIWA-container').style.display = 'block';
          niwaHasData = true;
        } else if (index === 2) {
          document.getElementById('om-container').style.display = 'block';
          omHasData = true;
        }
      }
    });

    // Show the left column
    const leftColumn = document.getElementById('left-column');
    leftColumn.style.display = 'block';

    // Show/hide tabs based on data availability
    document.getElementById('metservice-tab').style.display = metServiceHasData ? 'block' : 'none';
    document.getElementById('niwa-tab').style.display = niwaHasData ? 'block' : 'none';
    document.getElementById('om-tab').style.display = omHasData ? 'block' : 'none';

    // Switch to the first available tab
    if (metServiceHasData) {
      switchTab('metservice');
    } else if (niwaHasData) {
      switchTab('niwa');
    } else if (omHasData) {
      switchTab('om');
    }
  });
}

const BUCKET_URL = 'https://storage.googleapis.com/simpleweather-storage-bucket-dev/data-folder/data';
// const BUCKET_URL = "./data"

// Function to load MetService data excluding statements
async function loadMetServiceData(fileName) {
  // Extract just the filename from the path if provided
  const baseFileName = fileName.split('/').pop();
  const gcsUrl = `${BUCKET_URL}/${baseFileName}`;

  return new Promise((resolve, reject) => {
    Papa.parse(gcsUrl, {
      download: true,
      header: true,
      complete: function (results) {
        if (results.data.length > 0 && Object.keys(results.data[0]).length > 0 && results.data.length < 30) {
          // Process weather data table
          const weatherTable = document.getElementById('MetService-table');
          weatherTable.innerHTML = '';

          // Create weather data header
          const weatherHeader = document.createElement('tr');
          ['Date', 'Temperature', 'Precipitation', 'Overnight', 'Morning', 'Afternoon', 'Evening'].forEach(col => {
            const th = document.createElement('th');
            th.textContent = col;
            th.className = col.toLowerCase() + '-column';
            weatherHeader.appendChild(th);
          });
          weatherTable.appendChild(weatherHeader);

          // Fill weather data
          results.data.forEach(row => {
            if (Object.values(row).some(value => value !== "")) {
              const tr = document.createElement('tr');
              ['Date', 'Temperature', 'Precipitation', 'Overnight', 'Morning', 'Afternoon', 'Evening'].forEach(col => {
                const td = document.createElement('td');
                td.textContent = row[col] || '';
                td.className = col.toLowerCase() + '-column';
                tr.appendChild(td);
              });
              weatherTable.appendChild(tr);
            }
          });
          weatherTable.style.display = 'table';

          // Process statements table
          const statementsTable = document.getElementById('statements-table');
          statementsTable.innerHTML = '';

          // Fill statements (only first two days)
          for (let i = 0; i < Math.min(2, results.data.length); i++) {
            const row = results.data[i];
            if (row.Statement) {
              const tr = document.createElement('tr');

              const tdDate = document.createElement('td');
              tdDate.textContent = i === 0 ? "Today" : "Tomorrow";
              tdDate.className = 'statement-date-column';
              tr.appendChild(tdDate);

              const tdStatement = document.createElement('td');
              tdStatement.setAttribute('data-en', row['Statement'] || '');
              tdStatement.setAttribute('data-zh', row['Statement_Chinese'] || '');
              tdStatement.textContent = row['Statement'] || '';
              tdStatement.className = 'statement-column';
              tr.appendChild(tdStatement);

              statementsTable.appendChild(tr);
            }
          }
          statementsTable.style.display = 'table';

          resolve(true);
        } else {
          console.log(`No data found in ${fileName}`);
          resolve(false);
        }
      },
      error: function (error) {
        console.error(`Error loading CSV file ${fileName}:`, error);
        reject(error);
      }
    });
  });
}


function searchLocation() {
  var location = document.getElementById('location').value.toLowerCase();
  // if (location === 'lower hutt') {
  loadWeatherData(location);
  // } else {
  //   alert('Location not found. Try searching for "lower hutt".');
  // }
}

function capitalizeWords(str) {
  return str.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
}


// control adjust bar
function throttle(func, limit) {
  // Throttle function
  let last = 0;
  return function (...args) {
    const now = Date.now();
    if (now - last >= limit) {
      last = now;
      func(...args);
    }
  };
}


const leftColumn = document.querySelector('.left-column');
const resizeHandle = document.createElement('div');
resizeHandle.classList.add('resize-handle');
leftColumn.appendChild(resizeHandle);

let isResizing = false;
let initialX = 0;
let initialWidth = 0;

resizeHandle.addEventListener('mousedown', (event) => {
  isResizing = true;
  initialX = event.clientX;
  initialWidth = leftColumn.offsetWidth;
});

document.addEventListener('mousemove', throttle((event) => {
  if (isResizing) {
    const deltaX = event.clientX - initialX;
    const newWidth = initialWidth + deltaX;
    leftColumn.style.width = `${newWidth}px`;
  }
}, 10));

document.addEventListener('mouseup', () => {
  isResizing = false;
});

// Add event listener for the satellite icon
document.getElementById('satellite-icon').addEventListener('click', function () {
  if (satelliteLayer) {
    // Toggle the visibility of the satellite layer
    const isVisible = satelliteLayer.getVisible();
    satelliteLayer.setVisible(!isVisible);
  } else {
    // If the layer doesn't exist, load it
    loadLatestSatellite();
  }
});

// Add an event listener for the map's moveend event
map.on('moveend', function () {
  const zoomLevel = map.getView().getZoom(); // Get the current zoom level

  // Check if the zoom level is 12
  if (zoomLevel >= 11) {
    // Hide all overlays
    if (satelliteLayer) {
      satelliteLayer.setVisible(false); // Hide satellite layer
    }
    if (radarLayer) {
      radarLayer.setVisible(false); // Hide radar layer
    }
    // Add any other overlays you want to hide here
  } else {
    // Optionally, you can show the overlays again if the zoom level is less than 12
    // if (satelliteLayer) {
    //   satelliteLayer.setVisible(true); // Show satellite layer
    // }
    if (radarLayer) {
      radarLayer.setVisible(true); // Show radar layer
    }
  }
});

// Function to load NIWA data
async function loadNIWAData(fileName) {
  const baseFileName = fileName.split('/').pop();
  const gcsUrl = `${BUCKET_URL}/${baseFileName}`;

  return new Promise((resolve, reject) => {
    Papa.parse(gcsUrl, {
      download: true,
      header: true,
      complete: function (results) {
        if (results.data.length > 0 && Object.keys(results.data[0]).length > 0 && results.data.length < 30) {
          // Process weather data table
          const weatherTable = document.getElementById('NIWA-table');
          weatherTable.innerHTML = '';

          // Create weather data header
          const weatherHeader = document.createElement('tr');
          ['Date', 'Temperature', 'Precipitation', 'Wind'].forEach(col => {
            const th = document.createElement('th');
            th.textContent = col;
            th.className = col.toLowerCase() + '-column';
            weatherHeader.appendChild(th);
          });
          weatherTable.appendChild(weatherHeader);

          // Fill weather data (only first two days)
          for (let i = 0; i < Math.min(2, results.data.length); i++) {
            const row = results.data[i];
            if (Object.values(row).some(value => value !== "")) {
              const tr = document.createElement('tr');
              ['Date', 'Temperature', 'Precipitation', 'Wind'].forEach(col => {
                const td = document.createElement('td');
                td.textContent = row[col] || '';
                td.className = col.toLowerCase() + '-column';
                tr.appendChild(td);
              });
              weatherTable.appendChild(tr);
            }
          }
          weatherTable.style.display = 'table';

          // Process statements table
          const statementsTable = document.getElementById('niwa-statements-table');
          statementsTable.innerHTML = '';

          // Fill statements (only first two days)
          for (let i = 0; i < Math.min(2, results.data.length); i++) {
            const row = results.data[i];
            if (row.Statement) {
              const tr = document.createElement('tr');

              const tdDate = document.createElement('td');
              tdDate.textContent = i === 0 ? "Today" : "Tomorrow";
              tdDate.className = 'statement-date-column';
              tr.appendChild(tdDate);

              const tdStatement = document.createElement('td');
              tdStatement.setAttribute('data-en', row['Statement'] || '');
              tdStatement.setAttribute('data-zh', row['Statement_Chinese'] || '');
              tdStatement.textContent = row['Statement'] || '';
              tdStatement.className = 'statement-column';
              tr.appendChild(tdStatement);

              statementsTable.appendChild(tr);
            }
          }
          statementsTable.style.display = 'table';

          resolve(true);
        } else {
          console.log(`No data found in ${fileName}`);
          resolve(false);
        }
      },
      error: function (error) {
        console.error(`Error loading CSV file ${fileName}:`, error);
        reject(error);
      }
    });
  });
}

// Update the switchTab function to handle container names correctly
function switchTab(tabId) {
    // Hide all content containers
    const contentContainers = ['metservice-content', 'niwa-content', 'om-content'];
    contentContainers.forEach(id => {
        const contentElement = document.getElementById(id);
        if (contentElement) {
            contentElement.style.display = 'none';
        }
    });

    // Hide all data containers
    const dataContainers = {
        'metservice': 'MetService-container',
        'niwa': 'NIWA-container',
        'om': 'om-container'
    };
    Object.values(dataContainers).forEach(containerId => {
        const container = document.getElementById(containerId);
        if (container) {
            container.style.display = 'none';
        }
    });

    // Remove active class from all tabs
    const tabs = ['metservice-tab', 'niwa-tab', 'om-tab'];
    tabs.forEach(id => {
        const tabElement = document.getElementById(id);
        if (tabElement) {
            tabElement.classList.remove('active');
        }
    });

    // Show selected content and activate selected tab
    const selectedContent = document.getElementById(tabId + '-content');
    const selectedContainer = document.getElementById(dataContainers[tabId]);
    const selectedTab = document.getElementById(tabId + '-tab');

    if (selectedContent) {
        selectedContent.style.display = 'block';
    }
    if (selectedContainer) {
        selectedContainer.style.display = 'block';
    }
    if (selectedTab) {
        selectedTab.classList.add('active');
    }

    // Special handling for OM tab
    if (tabId === 'om' && window.omChart) {
        window.omChart.resize();
    }
}

// Add single set of event listeners for tabs
const tabs = ['metservice', 'niwa', 'om'];
tabs.forEach(tabId => {
    const tabElement = document.getElementById(tabId + '-tab');
    if (tabElement) {
        tabElement.addEventListener('click', () => switchTab(tabId));
    }
});

// Function to capitalize the first letter of a string
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

// Add this near the top with other constants
const WEATHER_API_KEY = import.meta.env.VITE_WEATHER_API_KEY || '';

// Add this for debugging during development
if (!WEATHER_API_KEY) {
    console.error('Weather API key is not set in environment variables');
}

// Function to get locations from GeoJSON
function getGeoJSONLocations(searchTerm) {
  const features = vectorSource.getFeatures();
  return features
    .map(feature => ({
      name: feature.get('name'),
      type: 'geojson',
      feature: feature
    }))
    .filter(location => 
      location.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base' }));
}

// Function to get all GeoJSON locations
function getAllGeoJSONLocations() {
  return vectorSource.getFeatures()
    .map(feature => ({
      name: feature.get('name'),
      type: 'geojson',
      feature: feature
    }))
    .sort((a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base' }));
}

// Define displayLocations function first
function displayLocations(locations) {
  const suggestionsContainer = document.getElementById('location-suggestions');
  
  if (locations.length === 0) {
    suggestionsContainer.style.display = 'none';
    return;
  }

  suggestionsContainer.innerHTML = '';
  locations.forEach(location => {
    const suggestionItem = document.createElement('div');
    suggestionItem.className = 'suggestion-item';
    
    if (location.type === 'geojson') {
      // Get the station type from the feature properties
      const stationType = location.feature.get('type');
      let typeLabel;
      
      // Convert type codes to readable labels
      switch(stationType) {
        case 'MS-only':
          typeLabel = 'MetService';
          break;
        case 'NIWA-only':
          typeLabel = 'NIWA';
          break;
        case 'MS-NIWA':
          typeLabel = 'MetService & NIWA';
          break;
        default:
          typeLabel = 'Open-Meteo';
      }

      suggestionItem.innerHTML = `
        <span style="color: black; font-weight: normal;">
          ${location.name}
        </span>
        <span style="color: #666; font-size: 0.8em;">
          (${typeLabel})
        </span>
      `;
    } else {
      suggestionItem.innerHTML = `
        <span style="color: #000;">
          ${location.name}
        </span>
        <span style="color: #666; font-size: 0.8em;">
          (Open-Meteo only)
        </span>
      `;
    }
    
    // Handle click events
    suggestionItem.onclick = async () => {
      const input = document.getElementById('location');
      input.value = location.name;
      suggestionsContainer.style.display = 'none';
      
      if (location.type === 'geojson') {
        const name = location.name.toLowerCase().replace(' ', '_');
        loadWeatherData(name);
        
        const coordinates = location.feature.getGeometry().getCoordinates();
        map.getView().animate({
          center: coordinates,
          zoom: 11,
          duration: 1000
        });
        
        if (highlightedFeature) {
          highlightedFeature.setStyle(null);
        }
        const clusterFeature = findClusterFeature(location.feature);
        if (clusterFeature) {
          clusterFeature.setStyle(createHighlightStyle(clusterFeature));
          highlightedFeature = clusterFeature;
        }
      } else {
        // Handle Open-Meteo locations
        input.value = location.name;
        
        const nztmCoords = transform(
          location.coordinates,
          'EPSG:4326',
          'EPSG:2193'
        );
        
        weatherApiSource.clear();
        
        const marker = new Feature({
          geometry: new Point(nztmCoords),
          name: location.name
        });
        weatherApiSource.addFeature(marker);
        
        map.getView().animate({
          center: nztmCoords,
          zoom: 11,
          duration: 1000
        });

        const leftColumn = document.querySelector('.left-column');
        leftColumn.style.display = 'block';
        if (leftColumn.classList.contains('collapsed')) {
          toggleLeftColumn();
        }

        document.getElementById('location-name').textContent = `${location.name}, New Zealand`;

        document.getElementById('metservice-tab').style.display = 'none';
        document.getElementById('niwa-tab').style.display = 'none';
        document.getElementById('MetService-container').style.display = 'none';
        document.getElementById('NIWA-container').style.display = 'none';

        document.getElementById('om-content').style.display = 'block';
        document.getElementById('om-container').style.display = 'block';
        
        const omTab = document.getElementById('om-tab');
        omTab.style.display = 'block';
        omTab.classList.add('active');
        
        fetchWeatherData(location.coordinates[1], location.coordinates[0])
          .then(() => {
            document.getElementById('om-content').style.display = 'block';
            document.getElementById('om-container').style.display = 'block';
            if (window.omChart) {
              window.omChart.resize();
            }
          })
          .catch(error => {
            console.error('Error fetching OM weather data:', error);
          });
      }
    };
    
    suggestionsContainer.appendChild(suggestionItem);
  });
  
  suggestionsContainer.style.display = 'block';
}

// Update the loadLocations function
async function loadLocations() {
  const input = document.getElementById('location');
  const suggestionsContainer = document.getElementById('location-suggestions');
  const searchTerm = input.value.trim();
  
  // Clear previous suggestions
  suggestionsContainer.innerHTML = '';
  
  // Show all GeoJSON locations if search is empty and input is focused/clicked
  if (searchTerm.length === 0 && document.activeElement === input) {
    const allLocations = getAllGeoJSONLocations();
    displayLocations(allLocations);
    return;
  }
  
  // Don't search if input is too short but not empty
  if (searchTerm.length < 2) {
    suggestionsContainer.style.display = 'none';
    return;
  }

  // Get filtered GeoJSON locations
  const geoJSONLocations = getGeoJSONLocations(searchTerm);
  
  // Always display GeoJSON locations first
  displayLocations(geoJSONLocations);

  // Try to fetch WeatherAPI locations
  try {
    if (WEATHER_API_KEY) {
      const response = await fetch(
        `https://api.weatherapi.com/v1/search.json?key=${WEATHER_API_KEY}&q=${encodeURIComponent(searchTerm)}&country=NZ`
      );
      
      if (response.ok) {
        const apiLocations = await response.json();
        const nzApiLocations = apiLocations
          .filter(loc => loc.country === 'New Zealand')
          .map(loc => ({
            name: loc.name,
            region: loc.region,
            type: 'api',
            coordinates: [loc.lon, loc.lat]
          }));

        // Combine and display all locations
        displayLocations([...geoJSONLocations, ...nzApiLocations]);
      } else {
        // If WeatherAPI fails, keep showing GeoJSON locations
        console.warn('WeatherAPI request failed, showing only GeoJSON locations');
      }
    }
  } catch (error) {
    console.error('Error fetching WeatherAPI locations:', error);
    // Keep showing GeoJSON locations even if WeatherAPI fails
  }
}

// Debounce helper function
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

// Update debounce delay to be shorter (from 300ms to 50ms)
const debouncedLoadLocations = debounce(loadLocations, 50);

// Add event listeners
document.getElementById('location').addEventListener('input', debouncedLoadLocations);

// Add event listener to show suggestions when the search bar is clicked
document.getElementById('location').addEventListener('click', () => {
  // console.log('Search input clicked'); // Debugging line
  loadLocations(); // Load locations to show suggestions
});

// Add event listener to the search input
document.getElementById('location').addEventListener('focus', () => {
  // console.log('Search input focused'); // Debugging line
  loadLocations();
});

// Add click event listener to show suggestions when the search bar is clicked
document.getElementById('location').addEventListener('click', () => {
  // console.log('Search input clicked'); // Debugging line
  loadLocations(); // Load locations to show suggestions
});

async function getLatLonByLocationName(locationName) {
  try {
    const response = await fetch('./data/locations.geojson');
    const geojsonData = await response.json();

    const features = new GeoJSON().readFeatures(geojsonData);
    const locationFeature = features.find((feature) => {
      // Match location name (case insensitive)
      return feature.get('name').toLowerCase() === locationName.toLowerCase();
    });

    if (!locationFeature) {
      console.warn(`Location "${locationName}" not found.`);
      return null;
    }

    // Get and transform coordinates to EPSG:4326
    const coord = locationFeature.getGeometry().getCoordinates();
    return { coord };
  } catch (error) {
    console.error('Error loading GeoJSON:', error);
  }
}

// Add event listener for OM tab
document.getElementById('om-tab').addEventListener('click', function() {
  // Hide other content containers
  document.getElementById('metservice-content').style.display = 'none';
  document.getElementById('niwa-content').style.display = 'none';
  document.getElementById('om-content').style.display = 'block';
  
  // Update active tab styling
  this.classList.add('active');
  document.getElementById('metservice-tab').classList.remove('active');
  document.getElementById('niwa-tab').classList.remove('active');
});

// Add these functions after your existing chart-related code

// Function to process Open Meteo data
function processOMData(data) {
    if (!data || !data.hourly) {
        console.error('Invalid data format from Open Meteo');
        return null;
    }

    // Process only the next 72 hours (3 days)
    const timeData = data.hourly.time.slice(0, 72).map(time => new Date(time));
    const precipData = data.hourly.precipitation.slice(0, 72);
    const tempData = data.hourly.temperature_2m.slice(0, 72);
    const windData = data.hourly.wind_speed_10m.slice(0, 72);
    const gustData = data.hourly.wind_gusts_10m.slice(0, 72);

    return {
        time: timeData,
        precipitation: precipData,
        temperature: tempData,
        wind: windData,
        gusts: gustData
    };
}

// Function to create temperature chart
function createTemperatureChart(data) {
    const ctx = document.getElementById('temperatureChart').getContext('2d');
    
    if (window.temperatureChart instanceof Chart) {
        window.temperatureChart.destroy();
    }

    // Get current time for the vertical line
    const currentTime = new Date();

    // Create annotations configuration
    const annotations = {};

    // Add current time annotation with arrow
    annotations.currentTime = {
        type: 'line',
        xMin: currentTime,
        xMax: currentTime,
        borderColor: 'rgba(0, 0, 0, 0.8)', // Change to black
        borderWidth: 2,
        label: {
            content: '▼ Now', // Add arrow symbol
            enabled: true,
            position: 'top',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            color: '#fff',
            padding: 4,
            font: {
                size: 12,
                weight: 'bold'
            }
        }
    };

    // Keep the day separator lines
    data.time.forEach((time, index) => {
        const date = new Date(time);
        const hour = date.getHours();
        if (hour === 0 && index > 0) {
            annotations[`dayLine${index}`] = {
                type: 'line',
                xMin: time,
                xMax: time,
                borderColor: 'rgba(200, 200, 200, 0.5)',
                borderWidth: 1,
                borderDash: [5, 5],
            }
        }
    });

    window.temperatureChart = new Chart(ctx, {
        type: 'line',
        data: {
            labels: data.time,
            datasets: [{
                label: 'Temperature (°C)',
                data: data.temperature.map((value, index) => ({
                    x: data.time[index],
                    y: value
                })),
                borderColor: 'rgba(255, 99, 132, 1)',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderWidth: 2,
                tension: 0.4,
                pointRadius: 0,
                pointHitRadius: 10,
                fill: true
            }]
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            interaction: {
                mode: 'index',
                intersect: false
            },
            plugins: {
                legend: {
                    display: true,
                    position: 'bottom'
                },
                annotation: {
                    annotations: annotations
                }
            },
            scales: {
                x: {
                    type: 'time',
                    time: {
                        unit: 'hour',
                        displayFormats: {
                            hour: 'HH:mm',
                            day: 'MMM d'
                        },
                        stepSize: 6
                    },
                    grid: {
                        display: false,
                    },
                    ticks: {
                        maxRotation: 0,
                        font: {
                            size: window.innerWidth < 600 ? 9 : 11
                        },
                        callback: function(value) {
                            if (!value) return '';
                            const date = new Date(value);
                            const hour = date.getHours();
                            if (hour === 0) {
                                return date.toLocaleDateString('en-NZ', {
                                    month: 'short',
                                    day: 'numeric'
                                });
                            }
                            return '';
                        }
                    }
                },
                y: {
                    beginAtZero: false,
                    title: {
                        display: true,
                        text: 'Temperature (°C)'
                    },
                    ticks: {
                        font: {
                            size: window.innerWidth < 600 ? 9 : 11
                        }
                    }
                }
            }
        }
    });
}

// Function to create precipitation and wind chart
function createPrecipWindChart(data) {
    const ctx = document.getElementById('precipWindChart').getContext('2d');
    
    if (window.precipWindChart instanceof Chart) {
        window.precipWindChart.destroy();
    }

    // Get current time for the vertical line
    const currentTime = new Date();

    // Create annotations configuration
    const annotations = {};

    // Add current time annotation with arrow
    annotations.currentTime = {
        type: 'line',
        xMin: currentTime,
        xMax: currentTime,
        borderColor: 'rgba(0, 0, 0, 0.8)', // Change to black
        borderWidth: 2,
        label: {
            content: '▼ Now', // Add arrow symbol
            enabled: true,
            position: 'top',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            color: '#fff',
            padding: 4,
            font: {
                size: 12,
                weight: 'bold'
            }
        }
    };

    // Keep the day separator lines
    data.time.forEach((time, index) => {
        const date = new Date(time);
        const hour = date.getHours();
        if (hour === 0 && index > 0) {
            annotations[`dayLine${index}`] = {
                type: 'line',
                xMin: time,
                xMax: time,
                borderColor: 'rgba(200, 200, 200, 0.5)',
                borderWidth: 1,
                borderDash: [5, 5],
            }
        }
    });

    window.precipWindChart = new Chart(ctx, {
        type: 'bar',
        data: {
            labels: data.time,
            datasets: [
                {
                    label: 'Precipitation (mm)',
                    data: data.precipitation.map((value, index) => ({
                        x: data.time[index],
                        y: value
                    })),
                    backgroundColor: 'rgba(54, 162, 235, 0.5)',
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 1,
                    barThickness: 3,
                    yAxisID: 'y-precipitation'
                },
                {
                    label: 'Wind Speed (km/h)',
                    data: data.wind.map((value, index) => ({
                        x: data.time[index],
                        y: value
                    })),
                    borderColor: 'rgba(75, 192, 192, 1)',
                    backgroundColor: 'rgba(75, 192, 192, 0.0)',
                    borderWidth: 2,
                    type: 'line',
                    yAxisID: 'y-wind',
                    tension: 0.4,
                    pointRadius: 0,
                    pointHitRadius: 10
                },
                {
                    label: 'Wind Gusts (km/h)',
                    data: data.gusts.map((value, index) => ({
                        x: data.time[index],
                        y: value
                    })),
                    borderColor: 'rgba(192, 192, 192, 1)',
                    backgroundColor: 'rgba(153, 102, 255, 0.0)',
                    borderWidth: 2,
                    type: 'line',
                    yAxisID: 'y-wind',
                    tension: 0.4,
                    pointRadius: 0,
                    pointHitRadius: 10,
                    borderDash: [5, 5]
                }
            ]
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            interaction: {
                mode: 'index',
                intersect: false
            },
            plugins: {
                legend: {
                    display: true,
                    position: 'bottom'
                },
                annotation: {
                    annotations: annotations
                }
            },
            scales: {
                x: {
                    type: 'time',
                    time: {
                        unit: 'hour',
                        displayFormats: {
                            hour: 'HH:mm',
                            day: 'MMM d'
                        },
                        stepSize: 6
                    },
                    grid: {
                        display: false,
                    },
                    ticks: {
                        maxRotation: 0,
                        font: {
                            size: window.innerWidth < 600 ? 9 : 11
                        },
                        callback: function(value) {
                            if (!value) return '';
                            const date = new Date(value);
                            const hour = date.getHours();
                            if (hour === 0) {
                                return date.toLocaleDateString('en-NZ', {
                                    month: 'short',
                                    day: 'numeric'
                                });
                            }
                            return '';
                        }
                    }
                },
                'y-precipitation': {
                    type: 'linear',
                    position: 'left',
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: 'Precipitation (mm)'
                    },
                    ticks: {
                        font: {
                            size: window.innerWidth < 600 ? 9 : 11
                        }
                    }
                },
                'y-wind': {
                    type: 'linear',
                    position: 'right',
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: 'Wind Speed (km/h)'
                    },
                    grid: {
                        display: false
                    },
                    ticks: {
                        font: {
                            size: window.innerWidth < 600 ? 9 : 11
                        }
                    }
                }
            }
        }
    });
}

// Update the fetchWeatherData function to use both charts
async function fetchWeatherData(latitude, longitude) {
    const url = `https://api.open-meteo.com/v1/forecast?latitude=${latitude}&longitude=${longitude}&hourly=temperature_2m,precipitation,wind_speed_10m,wind_gusts_10m&timezone=Pacific%2FAuckland&forecast_days=3`;
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const processedData = processOMData(data);
        if (processedData) {
            createTemperatureChart(processedData);
            createPrecipWindChart(processedData);
            return true;
        }
        return false;
    } catch (error) {
        console.error('Error fetching weather data:', error);
        return false;
    }
}

// Add these functions after your existing search-related code

// Initialize Google Places Autocomplete
function initializeAutocomplete() {
  const input = document.getElementById('location');
  
  // Create autocomplete object with NZ bounds
  const options = {
    bounds: new google.maps.LatLngBounds(
      new google.maps.LatLng(-47.302, 166.426), // SW bound of NZ
      new google.maps.LatLng(-34.129, 178.598)  // NE bound of NZ
    ),
    componentRestrictions: { country: 'nz' },
    fields: ['address_components', 'geometry', 'name'],
    strictBounds: true,
    types: ['geocode', 'establishment']
  };
  
  const autocomplete = new google.maps.places.Autocomplete(input, options);
  
  // Add place_changed event listener
  autocomplete.addListener('place_changed', function() {
    const place = autocomplete.getPlace();
    
    if (!place.geometry) {
      console.log("No location data for this place");
      return;
    }
    
    // Get latitude and longitude
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    
    // Transform coordinates from EPSG:4326 to EPSG:2193 (NZTM)
    const nztmCoords = transform([lng, lat], 'EPSG:4326', 'EPSG:2193');
    
    // Center map on the selected location
    map.getView().animate({
      center: nztmCoords,
      zoom: 11,
      duration: 1000
    });
    
    // Find nearest weather station
    const nearestFeature = findNearestLocation(nztmCoords);
    if (nearestFeature) {
      const name = nearestFeature.get('name').toLowerCase().replace(' ', '_');
      // Load weather data for the nearest station
      loadWeatherData(name);
      
      // Update search input with the weather station name
      input.value = nearestFeature.get('name');
      
      // Highlight the feature on the map
      if (highlightedFeature) {
        highlightedFeature.setStyle(null);
      }
      const clusterFeature = findClusterFeature(nearestFeature);
      if (clusterFeature) {
        clusterFeature.setStyle(createHighlightStyle(clusterFeature));
        highlightedFeature = clusterFeature;
      }
    }
  });
}

// Helper function to find the cluster feature containing a specific feature
function findClusterFeature(targetFeature) {
  let found = null;
  clusterSource.getFeatures().forEach(clusterFeature => {
    const features = clusterFeature.get('features');
    if (features.includes(targetFeature)) {
      found = clusterFeature;
    }
  });
  return found;
}

// Call initializeAutocomplete when the page loads
// document.addEventListener('DOMContentLoaded', function() {
//   initializeAutocomplete();
// });

// Add a resize handler to ensure proper rendering after container changes
window.addEventListener('resize', function() {
    if (window.omChart) {
        window.omChart.resize();
    }
});

// Create a vector source and layer for the WeatherAPI location marker
const weatherApiSource = new VectorSource();
const weatherApiLayer = new VectorLayer({
  source: weatherApiSource,
  style: new Style({
    image: new Circle({
      radius: 8,
      fill: new Fill({ color: 'red' }),
      stroke: new Stroke({ color: 'white', width: 2 })
    })
  })
});
map.addLayer(weatherApiLayer);

// Add this to clear the marker when clicking a GeoJSON location
if (location.type === 'geojson') {
  // Clear any WeatherAPI markers
  weatherApiSource.clear();
  
  // Rest of the GeoJSON location code...
}

// Add this to clear the marker when closing the left column
function toggleLeftColumn() {
  const leftColumn = document.querySelector('.left-column');
  const searchInput = document.getElementById('location');
  const suggestionsContainer = document.getElementById('location-suggestions');

  leftColumn.classList.toggle('collapsed');

  if (leftColumn.classList.contains('collapsed')) {
    searchInput.value = '';
    suggestionsContainer.style.display = 'none';
    weatherApiSource.clear(); // Clear the WeatherAPI marker
  }
}

// Add this function to update weather icons
async function updateWeatherIcons() {
  try {
    const weatherCodes = await loadWeatherCodes();
    
    // Update each feature's weather code
    vectorSource.getFeatures().forEach(feature => {
      const name = feature.get('name').toLowerCase().replace(' ', '_');
      feature.set('weatherCode', weatherCodes[name] || null);
    });
    
    // Force redraw of the vector layer
    vectorLayer.changed();
    
    console.log('Weather icons updated successfully at:', new Date().toLocaleTimeString());
  } catch (error) {
    console.error('Error updating weather icons:', error);
  }
}

// Initial load
updateWeatherIcons();

// Update weather icons every hour (3600000 milliseconds)
setInterval(updateWeatherIcons, 1800000);